.ourwork {
  padding-top: 100px;
  height: max-content;
  background-color: #014072;
  padding-bottom: 40px;

}

.ourwork_main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourwork_main_div1 {
  color: white;
}

.ourwork_main_div1 h1 {
  font-size: 45px;
    font-weight: 900;
}

.our-works p {
  font-size: 18px;
  max-width: 800px;
  margin: auto;
}

.work__tabbing {
  margin-top: 50px;
}


.work__tabbing_inner {
  margin: 20px 0;
display: flex;
justify-content: center;
flex-wrap: wrap;
}




.work__tabbing_inner button {
  font-weight: 500;
  color: #014072;
  padding-bottom: 2px;
  font-size: 18px;
  margin-bottom: 10px;
}



.work__tabbing_inner button:hover {
  color: rgb(246, 102, 102);
}

.example {
  position: relative;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  background-position: top center;
  display: block;
  cursor: pointer;
  overflow: hidden;

}

.clients {
  margin-bottom: 30px;
  animation: ease-in-out 5s;
}

.content {
  opacity: 0%;
  font-size: 40px;
  position: relative;
  top: 0;
  left: 0;
  color: #ffffff;
  background-color: rgba(200, 200, 200, 0.5);
  width: 100%;
  height: 100%;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
  text-align: center;
  padding: 20px;
}


.content::after {
  background: black;
  opacity: 70%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  color: #ffffff;
}

.content p::after {
  opacity: 1;
  color: white;

}

.content_link {
  color: #f32626;
}

.content p {
  font-size: 13px;
  display: -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  clear: both;
}

.example .content:hover {
  opacity: 1;
}

.example img {
  width: 50%;
}
.example2 img {
  width: 30% !important;
}

.example .content .text {
  height: 0;
  opacity: 1;
  color: white;
  transition-delay: 0s;
  transition-duration: 0.4s;
  position: relative;
  z-index: 1;
}



.example .content:hover .text {
  opacity: 1;
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
}


.content span {
  position: absolute;

}

.decoration.left-bottom,
.decoration.left-top {
  left: 0;
  border-left: .2rem solid #fff;
  -webkit-transform: translate(0);
  transform: translate(0);
  left: 0;
  border-left: .2rem solid #fff;
  -webkit-transform: translate(0);
  transform: translate(0);
}

.decoration {
  opacity: 1;
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  position: absolute;
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

.left-bottom,
.left-top {

  left: 0;
  border-left: .2rem solid #fff;
  -webkit-transform: translate(0);
  transform: translate(0);
  left: 0;
  border-left: .2rem solid #fff;
  -webkit-transform: translate(0);
  transform: translate(0);
}

.left-top {
  top: 0;
  border-top: .2rem solid #fff;
}

.left-bottom {
  bottom: 0;
  border-bottom: .2rem solid #fff;
}


.right-bottom,
.right-top {

  right: 0;
  border-right: .2rem solid #fff;
  -webkit-transform: translate(0);
  transform: translate(0);
}

.right-top {
  top: 0;
  border-top: .2rem solid #fff;
}

.right-bottom {
  bottom: 0;
  border-bottom: .2rem solid #fff;
}

.example:focus .content .decoration.left-top,
.example .content:hover .decoration.left-top {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
}

.example:focus .content .decoration.left-bottom,
.example .content:hover .decoration.left-bottom {
  -webkit-transform: translate(100%, -100%);
  transform: translate(100%, -100%);
}

.example:focus .content .decoration.right-top,
.example .content:hover .decoration.right-top {
  -webkit-transform: translate(-100%, 100%);
  transform: translate(-100%, 100%);
}

.example:focus .content .decoration.right-bottom,
.example .content:hover .decoration.right-bottom {
  -webkit-transform: translate(-100%, -100%);
  transform: translate(-100%, -100%);
}

.works_consultation {
  background-color: #014072;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.works_consultation button {
  outline: 1px solid #fff;
  ;

color: #014072;
    background-color: #FFF;
    transition: all 0.4s;
}

.works_consultation button:hover {
  color: #014072;
  background-color: #FFF;

  transition: all 0.4s;
}

.works_consultation img {
  margin-left: 20%;
}

.seowork {
  padding-top: 100px;
  height: max-content;
background-image: url(../../assets/images/backgroundImage.jpg);
  padding-bottom: 40px;

}

.seowork_main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seowork_main_div1 {
  color: white;
}

.seowork_main_div1 h1 {
  font-size: 45px;
  font-weight: 900;
}



.seowork_heading p {
  font-size: 45px;
  color: #014072;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
}

.seowork_heading span {
  font-size: 15px;
  font-weight: 600;
  text-align: center;

}



.seotablediv {

  border-radius: 10px;
}

.seoimg img {
  border-radius: 10px;
}

table.table-striped {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
  border: 1px solid #fff;
  width: 100%;
}

table.table-striped thead tr:nth-child(1) th:nth-child(1) {
  border-top-left-radius: 10px;
  background: rgb(255 255 255 / 46%);
}

table.table-striped thead tr:nth-child(1) th:nth-child(2) {
  border-top-right-radius: 10px;
  background: rgb(255 255 255 / 46%);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

table.table-striped th:not(:last-child),
table.table-striped td:not(:last-child) {
  border-right: 1px solid #fff;
}

.table.table-striped tr th {
  background-color: #014076;
  color: #fff;
  line-height: 42px;
  padding: 0 15px !important;
  text-align: left;
  font-weight: bold;
}

.table.table-striped tr th {
  background-color: #014076;
  color: #fff;
  line-height: 42px;
  padding: 0 15px;
}

.table-striped td,
.table-striped th {
  border-top: 1px solid #fff !important;
  padding: 9px;
  vertical-align: top;
}



.case-st th {
  text-transform: capitalize;

}

tr {
  border-top: 1px solid white;
}

.seorankingtable {
  background-color: #486b88;
  color: #F7F7F7;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  padding-bottom: 50px;
}

.seorankingtable2 {
  background-color: #02335b;
  color: #F7F7F7;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  padding-bottom: 50px;
}


.seorankingtable3 {
  background-color: #486b88;
  color: #F7F7F7;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  padding-bottom: 50px;
}

.seorankingtable4 {
  background-color: #02335b;
  color: #F7F7F7;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  padding-bottom: 50px;
}

.seorankingtable5 {
  background-color: #486b88;
  color: #F7F7F7;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 30px;
  padding-bottom: 50px;
}

.seorankingtable a {
  background-color: black;
  color: #FFF;
  padding: 5px;

}

.seorankingtable2 a {
  background-color: black;
  color: #FFF;
  padding: 5px;

}


.seorankingtable3 a {
  background-color: black;
  color: #FFF;
  padding: 5px;

}


.seorankingtable4 a {
  background-color: black;
  color: #FFF;
  padding: 5px;

}

.seorankingtable5 a {
  background-color: black;
  color: #FFF;
  padding: 5px;

}

.theading{
  width: 150px;
}

@media only screen and (max-width:767px){
  .work__tabbing_inner button {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .works_consultation img {
   margin: 0;
   padding-top: 30px;
}
}

