a{text-decoration: none;}
a:hover{color: green;}
.home-blog{padding: 60px 0;}
.post-frame{background-color: #fff; box-shadow:2px 5px 10px -3px rgba(0, 0, 0, 0.24); border-radius: 5px; transition: all linear 0.3s;}
.post-frame:hover{box-shadow:2px 8px 20px -3px rgba(0, 0, 0, 0.24);}
.post-content{padding: 15px;}
.post-content h5 {color: #212529;font-size: 18px;line-height: 23px;font-weight: 600; transition: all linear 0.3s;}
.post-frame:hover h5{color: #177a0e;}
.post-content p {color: #212529;font-size: 16px;line-height: 20px;}

.post-frame h5{
display: -webkit-inline-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
clear: both;
}

.latest_blog img{
    height: 250px;
}

@media screen and (max-width:768px) {
    .latest_blog img{
        height: auto !important;
    }
}

