.blog{
  padding-top: 100px;
  height: max-content;
  background-color: #014072;
  padding-bottom: 40px;
 
}

.blog_main{
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_main_div1{
  color: white;
}

.blog_main_div1 h1 {
  font-size: 45px;
  font-weight: 900;
}

@keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    }
  }
  .blog-banner{position: relative;}
  

 
  .blog-input-group
  {
    display: flex;
justify-content: center;

  }

  .blog-input-group input{
    width: 60%;
    height: 40px;
    border: none;
    outline: 1px solid rgb(235, 234, 234);
    padding-left: 10px;
   border-top-left-radius: 5px;
   border-bottom-left-radius: 5px;
  }

  .blog-input-group button{
    width: 40px;
    height: 40px;
    border: none;
 background-color: #0078D6;
 color: white;
 outline: 1px solid #0078D6;
 font-weight: 20px;
 border-top-right-radius: 5px;
   border-bottom-right-radius: 5px;
 
  }

  .post-box{

    overflow:unset;
    margin: 10px;

  }

  .post-box img{
     border-radius: 10px;
  }

  .post-box p{
    font-size: 14px;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    clear: both;
  }

  .post-box h5{
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    clear: both;
  }
  .postbox_link{
    font-size: 13px;
    outline: 1px solid  #0078D6;
    padding: 7px 12px;
    color: #0078D6;
   font-weight: 600;
  }

  .postbox_link:hover{
    font-size: 13px;
    outline: 1px solid  #0078D6;
    padding: 7px 12px;
    background-color: #0078D6;
    color: #fff;
    transition: all 0.6s ;
   
  }

  .relatedblog_post{

margin-bottom: 80px;
  }

  .relatedpost-box img{
    max-height: 150px;
   
    border-radius: 10px;
  }

    .relatedpost-box h5{
    font-size: 17px;
  }

  .relatedpost-box p{
    font-size: 13px;
  }

  .relatedpostbox_link{
    font-size: 13px;

   
    color: #0078D6;
    text-decoration: underline;
   font-weight: 600;
  }

  .blog_post img{
    height: 250px;
  }
 
  
  @media only screen and (max-width:575px){
    .blog_post img{
      height: 280px !important;
    }
   
  }

  @media only screen and (max-width:577px){
    
  

    .post-box img{
        width: 100%;
        max-width: 100%;
     }

    .post-box{
        margin-left: auto;
     
      
       }
  
    }

    @media only screen and (min-width:467px){
    

      
        }

        .blog_post h6{
          display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    clear: both;
        }