#header .navbar {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}
a:focus,
input:focus,
.form-control:focus,
.btn:focus {
  box-shadow: none;
}
.btn {
  border-radius: 0px;
}
ul.navbar-nav li a.nav-link {
  margin: 0px 5px;
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 500;
}
ul.navbar-nav li div.nav-link {
  margin: 0px 5px;
  color: #1b1b1b;
  font-size: 16px;
  font-weight: 500;
}
ul.navbar-nav li a.nav-link:hover,
ul.navbar-nav li a.nav-link:active,
ul.navbar-nav li a.nav-link.active,
ul.navbar-nav li a.nav-link:focus {
  color: #177a0e;
}
ul.navbar-nav li div.nav-link:hover,
ul.navbar-nav li div.nav-link:active,
ul.navbar-nav li div.nav-link.active,
ul.navbar-nav li div.nav-link:focus {
  color: #177a0e;
}
.btn-contact {
  background-color: #177a0e;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 10px 5px 30px;
  border-radius: 0;
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
}
.btn-contact:hover {
  color: #fff;
}
.btn-contact::before {
  content: "";
  position: absolute;
  left: -8px;
  top: 10px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #177a0e;
  animation: pulse-animation1 2s infinite;
}
.btn-contact::after {
  position: absolute;
  content: "";
  background-color: #fff;
  height: 2px;
  width: 18px;
  left: 0;
  top: 15px;
}
@keyframes pulse-animation1 {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 120, 214, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 120, 214, 0);
  }
}

.main_logo {
  max-width: 190px;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .submenu {
  position: absolute;
  display: none;
}

.dropdown-menu > li:hover > .submenu {
  display: block;
  left: 100%;
  top: 0;
}

.dropdownmenu {
  position: relative;
}

.submenuu {
  position: absolute;
  display: none;
}

.dropdownmenu:hover .submenuu {
  transition: all 2s ease-in-out;
  display: block;
  /* width: 800px; */
  width: 950px;
  background-image: url(../../assets/images/Pattern.jpg);
  /* left: -300px; */
  left: -340px;
  top: 40px;
  padding: 30px;
  border-radius: 5px;
  border: 1px solid #d8d4d4;
}

.dropdownmenucol a {
  font-size: 14px;
  display: block;
  color: #1b1b1b;
  padding-top: 2px;
  padding-bottom: 2px;
}

.dropdownmenucol a:hover {
  padding-left: 15px;
  transition: all 0.3s ease-in-out;

  background-color: #1b1b1b;
  color: white;
}

.dropdownIcon {
  display: none;
  margin-right: 10px;
}

.dropdownmenucol a:hover .dropdownIcon {
  display: inline-block;
  transition: all 0.4s smooth;
}

.dropdownmenucol span {
  color: #1b1b1b;
  font-weight: 600;

  display: block;
}

.underline {
  border-bottom: 2px solid rgb(37, 3, 3);
  width: 50%;
}

/* .dropdownmenu .submenuu{
  overflow: scroll;
} */

/*----------------------------*/
.small_header .navbar-toggler:focus {
  box-shadow: none !important;
  border: 2px solid #15ad03 !important;
}
.small_header .navbar-toggler {
  border: 2px solid #15ad03 !important;
  color: #15ad03;}
.small_header .offcanvas .btn-close:focus {
  outline: auto !important;
  border:1px solid #15ad03 !important ;
}
.small_header .offcanvas .btn-close{
  border:1px solid #15ad03 !important ;
  color: #15ad03 !important;
}
.small_header .dropdown-item:focus, .small_header .dropdown-item:active{
  background-color: #15ad03 !important;
  color: white !important;
}
.small_header #offcanvasNavbar{
  width: 300px !important;
  border-right: none !important;
}
.small_header .navbar .nav-link{
  font-weight: 600 !important;
}
.small_header .navbar .dropdown-menu .dropdown-toggle{
  font-weight: 500 !important;
}

@media only screen and (max-width: 991px) {
  .dropdownmenu:hover .submenuu {
    transition: all 2s ease-in-out;
    display: block;
    width: 650px;
    /* width: 850px; */
    left: 0px;
    top: 30px;
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #d8d4d4;
    z-index: 99;
  }
}

@media only screen and (max-width: 767px) {
  .dropdownmenu:hover .submenuu {
    /* background-image: url(http://localhost:3000/static/media/Pattern.3261e91c47de7491b551.jpg); */
    border: 1px solid #d8d4d4;
    background-image: none;
    background-color: bisque;
    border-radius: 5px;
    display: block;
    left: 0;
    padding: 30px;
    top: 30px;
    transition: all 2s ease-in-out;
    width: 250px;
    z-index: 999;
    max-height: 200px;
    /* overflow: scroll; */
  }
  .small_header #offcanvasNavbar{
    width: 270px !important;
  }
  .small_header .navbar .dropdown-item{
    font-size: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .small_header .navbar .dropdown-item{
    font-size: 14px;
  }
}