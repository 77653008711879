body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  scroll-padding-top: 150px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

._1yCVn {
  width: 360px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 24px 0px;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  transform: translate3d(0px, 0px, 0px);
  touch-action: auto;
  display: none;
  position: fixed;
  bottom: 110px;
  right: 25px;
  z-index: 99999;
}

._2qp0Z:hover {
  background-color: #5ad167;
}

._2qp0Z {
  align-items: center;
  align-self: flex-end;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
  color: rgb(79, 206, 93);
  cursor: pointer;
  display: flex;
  font-size: 2em;
  height: 64px;
  justify-content: center;
  outline: transparent;
  -webkit-user-select: none;
  user-select: none;
  width: 64px;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  position: fixed;
  bottom: 100px !important;
  right: 25px;
  transition: 0.2s ease;
  z-index: 99999;
}



.about-team {
  max-height: 390px;
  object-fit: cover;
  object-position: top;
}


/*-----------page-not-found------------*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}


.dev-passion li{
  list-style: none;
}



