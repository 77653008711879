.contact__back{
  background-color: #014072;
  padding:50px 50px;
  border-radius: 10px;
  margin-top: 100px;
  margin-bottom: 100px;
}

.contact___form{
  padding: 50px 50px;
  background-color: whitesmoke;

  border-radius: 10px;
}

.contact__sidebar{
  padding: 50px 33px;
  background-color: whitesmoke;

  height: 100%;
  border-radius: 10px;
}

.star{
  color: red;
}

.contact__button{
  background-color: #0078D6;
  color: #fff;
}

.contact__button:hover{
  background-color: #0078D6;
  color: #fff;
}

.red__line{
  border: 1px solid #0078D6;
  width: 100%;
}

.contact__sidebar_Link{
  color:#0078D6; 
  margin-bottom: 130px;
}

.contact__sidebar p{

  margin-bottom: 30px;
}

.contact__content{
  color: white;
  margin-top: 100px;
}

.contact_ceodetail{
  background-color: whitesmoke;
  color: black;
  padding: 20px;
  border-radius: 10px;
}

.contact_ceodetail span{
  color:#0078D6; 
}

.blue__line{
  border-bottom: 1px solid #0078D6;
  max-width: 300px;
margin: auto;
}

.insta_con_icon{
  color:(45deg, #833AB4, #FD1D1D, #F56040, #FCAF45, #FFDC80);
}

.lets_talk .contact__items p img{
  width:25px;
  height: 20px;
  border-radius: 4px;
}
.contact__items .insta_img{
  height: 30px !important;
  width: 30px !important;
}
@media only screen and (max-width:991px){
  .contact__sidebar {
    margin: 25px 0;
}

.contact__items{
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  margin: 0 0 35px 0;
}
}

@media only screen and (max-width:767px){
  .contact__sidebar {
    margin: 25px 0;
}
}

@media only screen and (max-width:567px){
  .contact__sidebar {
    padding: 30px 20px;
    background-color: whitesmoke;
    height: 100%;
    border-radius: 10px;
}
.contact__back{
  padding:30px 20px;
}
.contact___form {
  padding: 30px 20px;
}
.contact_ceodetail {
  font-size: 13px;
}
}