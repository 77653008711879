/* .img-hover-box {
    height: 570px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 20px;
} */
.box-title h3 {
    font-size: 22px;
}
.body-galler{
    height: 100% !important;
    background-position: center;
    background-size: cover;
}
.img-hover-box {
    position: relative;
    z-index: 1;
    color: #fff;
    transition: all linear 0.3s;
}

.img-hover-box {
    justify-content: center;
    height: 220px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 17px;
}

.body-gallery{position: relative;}
.body-gallery::before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.4);
}

.body-galler::after {
    top: 0;
    background: #0006;
    width: 100%;
    position: absolute;
    content: "";
    height: 650px;
}

.img-hover-box i {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    position: relative;
    left: 100%;
    cursor: pointer;
    opacity: 0;
}
.img-hover-box:hover i{
    opacity: 1;
    position: relative;
    z-index:
     1;
}
/* .img-hover-box:hover .feature-img {
    width: 100%;
    position: absolute;
    left: 0;
    height: 200px;
    background-size: cover;
    transition: all linear 0.5s;
    transition-duration: 0.5s;
  
} */
.img-hover-box:hover .feature-img {
    width: 100%;
    position: absolute;
    left: 0;
    height: 219px;
    background-size: cover;
    transition: all linear 0.5s;
    transition-duration: 0.5s;
}

.gallery-modal-content{
    background: transparent;
    border: none;
    
}


.gallery-modal-header{

    border-bottom: none;
   

}
.gallery-modal-custom {
    height: 500px;
   
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #fffdfd;
    background: transparent url("../../assets/images/close.png") center/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: 1;
}


.btn-close:focus{
    box-shadow: none !important;
}
@media only screen and (max-width: 767px) and (min-width: 320px)  {
.body-galler::after {
    height: 148vh !important;
}
}

.gallerymodal{
    border: none;
}




