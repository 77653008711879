.side-icons{position: fixed; bottom:100px; left:0; z-index: 1;}
.social-icons ul li{list-style: none; padding-bottom: 10px;}
.social-icons ul li p {display: inline;padding-bottom: 70px;-webkit-writing-mode: vertical-rl;-ms-writing-mode: tb-rl;writing-mode: vertical-rl;-webkit-transform-origin: center;transform-origin: center;-webkit-transform: rotate(-180deg);transform: rotate(-180deg);position: relative;color: #0078D6;padding-left: 0;margin-left: 0;line-height: 30px;margin-top: 20px;font-size: 20px;font-weight: 600;}
.social-icons ul li p::after {content: " ";width: 3px; height: 55px;background: #0078D6;position: absolute;bottom: 0;left: calc(50% - 1.5px);}
.social-icons .iconify{font-size: 40px; color: #0078D6;}


@media screen and (max-width:768px) {
    .side-icons{
        display: none !important;
    }
}