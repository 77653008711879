.seo{
  padding-top: 100px;
  background-color: #2c8d85;
  padding-bottom: 40px;
  height: max-content;
}

.seo_main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seo_main .div1{
  color: #f1f2f3;
}

.seo_main h1 {
  font-size: 45px;
  font-weight: 900;
}

.seo_btn_section {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}


.seo_btn_section_div1 {
  line-height: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.seo_btn_section_div1 span {
  font-weight: 600;
}

.seo_btn_section_div1 h1 {
  font-weight: 900;
}

.how-we-can-help {
  margin-top: 50px;
}

.experience-seo {
  background-color: #f1f2f3;
}

.experience-seo div {
  border: 1px solid white;
  padding: 5px;
}





.seo_roundslider{
    background-color: #f1f2f3;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 30px 0;
    text-align: center;

}

.skill {
  width: 160px;
  height: 160px;
  position: relative;
  margin: auto;
}



.outer_box {
  box-sizing: border-box;
  height: 160px;
  width: 160px;
    background-color: #f1f2f3;
  border-radius: 50%;
  padding: 20px;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);

}

.inner_box {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    -0.5px -0.5px 0px rgba(255, 255, 255, 1),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15),
    0px 12px 10px -10px rgba(0, 0, 0, 0.05);
}

#number1 span {
  font-size: 35px;
  font-weight: 600;
}

#number2 span {
  font-size: 35px;
  font-weight: 600;
}

#number3 span {
  font-size: 35px;
  font-weight: 600;
}

#number4 span {
  font-size: 35px;
  font-weight: 600;
}

circle {
  fill: none;
stroke: url(#GradientColor);
  stroke-width: 20px;
  stroke-dasharray: 472;
  stroke-dashoffset: 472;
animation: anim 2s linear forwards;
}

.circle2 {
  fill: none;
stroke: url(#GradientColor2);
  stroke-width: 20px;
  stroke-dasharray: 472;
  stroke-dashoffset: 472;
animation: anim2 2s linear forwards;
}

.circle3 {
  fill: none;
stroke: url(#GradientColor3);
  stroke-width: 20px;
  stroke-dasharray: 472;
  stroke-dashoffset: 472;
animation: anim3 2s linear forwards;
}



.circle4 {
  fill: none;
stroke: url(#GradientColor4);
  stroke-width: 20px;
  stroke-dasharray: 472;
  stroke-dashoffset: 472;
animation: anim4 2s linear forwards;
}


@keyframes anim {
  100%{
    stroke-dashoffset: 165.2;
  }
}

@keyframes anim2 {
  100%{
    stroke-dashoffset: 141.6;
  }
}


@keyframes anim3 {
  100%{
    stroke-dashoffset: 118;
  }
}


@keyframes anim4 {
  100%{
    stroke-dashoffset: 70.8;
  }
}



.seosvg{
position: absolute;
top: 0;
left: 0;
}


.hover_form {
  padding: 50px 50px;
  background-color: #f1f2f3;
  border-radius: 10px;
  max-width: 450px;
  /* margin: auto; */
  margin-left: auto;
}

.forminputlabel{
  color: black;
}

.form_button {
  width: 100%;
  border-radius: 5px;
  margin: 5px 0px;
  font-size: 12px;
  height: 38px;
}

.form_custom_input
{
  padding: 10px;
}

@media screen and (max-width:475px) {
  .hover_form {
    padding: 35px 25px;
  }
}