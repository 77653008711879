section#home-banner {
  height: 850px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  position: relative;
  background-position: 50%;

}

section#home-banner::after {
  position: absolute;
  content: "";
  background-color: #000000b0;
  height: 850px;
  width: 100%;
}

.banner-content {
  position: relative;
  z-index: 1;
}

.mouse-effect {
  display: flex;
  justify-content: center;
}

.mouse-effect p {
  position: absolute;
  top: -85px;
  ;
  z-index: 11;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  writing-mode: vertical-rl;
  transform-origin: center;
  transform: rotate(-180deg);
}

.mouse-effect {
  position: relative;
}

.mouse-effect img {
  margin-top: -18px;
}

.banner-content h1 {
  font-size: 55px;
  line-height: 65px;
  font-weight: 700;
}

.banner-content p {
  /* margin-top: 0;
  margin-bottom: 1rem; */
  font-size: 22px;
  padding: 16px 0;
}

/* .button button.btn.btn-primary {font-size: 12px;padding: 10px 25px;font-family: 'DM Sans';font-weight: 700;text-transform: uppercase;} */

.start-btn {
  background-color: #177a0e;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 30px 10px 50px;
  border-radius: 0;
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
  margin: 10px;

}

.start-btn:hover {
  color: #fff;
}

.start-btn::before {
  content: '';
  position: absolute;
  left: -6px;
  top: 16px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fff;
  animation: pulse-animation 2s infinite;
}

.start-btn::after {
  position: absolute;
  content: '';
  background-color: #fff;
  height: 2px;
  width: 27px;
  left: 0;
  top: 21px;
}

.mouse {
  background-color: #177a0e;
  border-radius: 15px;
  box-shadow: 0 2px 8px #177a0e(0, 120, 214, .9);
  height: 40px;
  margin-top: -20px;
  overflow: hidden;
  position: relative;
  width: 26px;
  cursor: pointer;
}

.mouse .roll {
  position: absolute;
  top: 8px;
  left: 50%;
  width: 4px;
  margin-left: -2px;
  height: 6px;
  border-radius: 4px;
  background: #fff;
  -webkit-animation: mouseScroll .8s cubic-bezier(.7, 0, .3, 1) infinite alternate;
  animation: mouseScroll .8s cubic-bezier(.7, 0, .3, 1) infinite alternate;
  z-index: 2;
}

.mouse .rollshadow {
  position: absolute;
  top: 8px;
  left: 50%;
  width: 4px;
  margin-left: -2px;
  height: 6px;
  background: #aaa;
  border-radius: 4px;
  -webkit-animation: mouseScroll .8s cubic-bezier(.7, 0, .3, 1) infinite alternate;
  animation: mouseScroll .8s cubic-bezier(.7, 0, .3, 1) infinite alternate;
  animation-delay: 0s;
  -webkit-animation-delay: 80ms;
  animation-delay: 80ms;
  z-index: 1;
}

@-webkit-keyframes mouseScroll {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    -webkit-transform: translateY(14px);
    transform: translateY(14px)
  }
}

@keyframes mouseScroll {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    -webkit-transform: translateY(14px);
    transform: translateY(14px)
  }
}

.process {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 100px 0 50px;
}

.bx-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.work-process {
  background-color: #f7f7f7;
  width: 380px;
  height: 380px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 6px 0 5px 2px #ccc;
}

.work-process h3 {
  font-size: 38px;
  font-weight: 500;
  line-height: 49px;
}

.work-process h3 span {
  color: #177a0e;
  font-weight: 700;
}

.p1 {
  display: flex;
  position: relative;
}

.p2 {
  display: flex;
  position: relative;
  left: -30px;
}

.p3 {
  display: flex;
  position: relative;
}

.p4 {
  display: flex;
  position: relative;
  right: -30px;
}

.c1 {
  background-color: #E6E2F4;
}

.c2 {
  background-color: #E6ECEC;
}

.c3 {
  background-color: #F4E2EA;
}

.text-boxl {
  text-align: right;
 align-content: center;
  width: 96%;
  border-radius: 100px;
  padding: 10px 121px 0 15px;
  height: 125px;
  overflow: hidden;
  margin-bottom: 50px;
}

.text-boxl h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 5px;
}

.text-boxl p {
  /* font-size: 14px; */
  font-size: 12px;
  line-height: 14px;
  color: #1b1b1b;
}

.num-boxl {
  background-color: #f7f7f7;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: inset -2px 0 5px 2px #ccc;
  position: absolute;
  right: 0;
  top: -3px;
}

.num-boxl h6 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
}

.num-boxl::after {
  content: "";
  box-shadow: 5px 5px 10px #999;
  position: absolute;
  right: 0px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.nc1 {
  color: rgba(95, 76, 163, 0.3);
}

.nc2 {
  color: rgba(0, 153, 46, 0.3);
}

.nc3 {
  color: rgba(203, 30, 101, 0.3);
}

.text-boxr {
  text-align: left;
  width: 96%;
  border-radius: 100px;
  padding: 10px 15px 0 130px;
  height: 125px;
  overflow: hidden;
  margin-bottom: 50px;
  align-content: center;
}

.text-boxr h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 5px;
}

.text-boxr p {
  /* font-size: 14px; */
  /* line-height: 17px; */
  font-size: 12px;
  line-height: 14px;
  color: #1b1b1b;
}

.num-boxr {
  background-color: #f7f7f7;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: inset 2px 0 5px 2px #ccc;
  position: absolute;
  left: -5px;
  top: -3px;
}

.num-boxr h6 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
}

.num-boxr::after {
  content: "";
  box-shadow: -5px 5px 10px #999;
  position: absolute;
  left: 0px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
}

.l1 {
  position: absolute;
  right: -84px;
  top: 86px;
  z-index: 1;
}

.l2 {
  position: absolute;
  right: -76px;
  top: 64px;
  z-index: 0;
}

.l3 {
  position: absolute;
  right: -84px;
  top: 23px;
  z-index: 0;
}

.l4 {
  position: absolute;
  left: -94px;
  top: 91px;
  z-index: 1;
  transform: rotate(170deg);
}

.l5 {
  position: absolute;
  left: -76px;
  top: 64px;
  z-index: 0;
  transform: rotate(170deg);
}

.l6 {
  position: absolute;
  left: -84px;
  top: 23px;
  z-index: 0;
  transform: rotate(170deg);
}


.counter-stats {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0;
}

.stats {
  text-align: center;
  border-radius: 10px;
  border: 1px solid #fff;
  padding: 25px 0;
}

.plusclient {
  color: #fff;
  display: contents;
  font-size: 30px;
}

.stats .counting {
  color: #fff;
  display: inline;
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  line-height: 50px;
}

.stats h5 {
  color: #0ea200;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}

.stats-icon {
  background-color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  line-height: 76px;
  text-align: center;
  margin: -65px auto 15px;
}

.cact {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  margin: 50px 0;
  height: 365px;
  border-radius: 10px;
}

.call-to-action h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 31px;
  color: #fff;
}

.call-to-action p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #fff;
}

.btn-action {
  background-color: #fff;
  color: #000;
  text-transform: uppercase;
  padding: 10px 30px 10px 50px;
  border-radius: 0;
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
}

.btn-action:hover {
  color: #000;
}

.btn-action::before {
  content: '';
  position: absolute;
  left: -7px;
  top: 16px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #000;
  animation: pulse-animation3 2s infinite;
}

.btn-action::after {
  position: absolute;
  content: '';
  background-color: #000;
  height: 2px;
  width: 27px;
  left: 0;
  top: 21px;
}

@keyframes pulse-animation3 {
  0% {
    box-shadow: 0 0 0 0px rgba(27, 27, 27, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(27, 27, 27, 0);
  }
}

.home-abt {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.about-frame {
  padding-right: 70px;
}

.about-frame h3 {
  color: #1b1b1b;
  font-size: 30px;
  line-height: 39px;
  font-weight: 700;
}

.about-frame h6 {
  color: #177a0e;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}

.about-frame p {
  color: #1b1b1b;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}

.about-frame a {
  color: #0078D6;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}



.about-frame .new-get {
  margin-left: 10px;
  color: #fff;
  background-color: #0078D6;
  width: 25px;
  height: 25px;
  line-height: 22px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  animation: pulse-animation4 2s infinite;
}

@keyframes pulse-animation4 {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 120, 214, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 120, 214, 0);
  }
}

.portfolio {
  margin-top: 20px;

}

.portfolio h3 {
  color: #1b1b1b;
  font-size: 30px;
  line-height: 39px;
  font-weight: 700;
}

.portfolio h6 {
  color: #177a0e;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}

.client {
  margin-top: 20px;

}

.client h3 {
  color: #1b1b1b;
  font-size: 30px;
  line-height: 39px;
  font-weight: 700;
}

.client h6 {
  color: #177a0e;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}


.client2 {
  background-image: url(../../assets/images/Logo/worldmap4.png);
  padding-top: 30px;
  padding-bottom: 30px;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  text-align: center;
}

.client_reviews h2 {
  /* color: green; */
  font-size: 30px;
  line-height: 39px;
  font-weight: 700;
}

.client_reviews h6 {
  color: #177a0e;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}

.client_reviews .bgd {
  border-radius: 4px;
  border-radius: 15px;
}

.client_reviews iframe {
  width: 100%;
  height: 300px;
  /* padding: 36px; */

}

.plus {
  color: #fff;
  display: contents;
  font-size: 30px;
}

/* 
div#client2_image img {
  width: 200px;
  height: 55px;

} */

.businesspartner {
  padding-top: 10px;
  padding-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

}


.businesspartner h3 {
  color: #1b1b1b;
  font-size: 30px;
  line-height: 39px;
  font-weight: 700;
}

.businesspartner img {
  margin-top: auto;


}

.businesspartner h6 {
  color: #177a0e;
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
}

/* .mobile_lang,.mobile_techno{
  position: relative;
}
.mobile_lang li{
  list-style: none;
}
.mobile_techno li{
  list-style-type: numeric;
  margin-bottom: 5px;
}
.mobile_back{
  background:url(../../../public/img/back6.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  position: relative;
  padding: 40px 10px;
  border-radius: 15px;
}
.mobile_back::before{
  position: absolute;
  content: '';
  background: rgba(0,0,0,0.3);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;

}
.mobile_techno .btn-action{
  background-color: #0ea200 !important;
  color: white !important;
} */


.mobile_dev li{
  list-style: none;
  font-size: 16px;
  margin-bottom: 10px;
}
.mobile_no{
  background-color: #0ea200;
  color: white;
  padding: 10px 12px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 13px;
}

.home_process{
  background-color: whitesmoke;
  padding: 50px 0;
}
.home_process .card{
  height: 260px;
  border: 0  !important;
  border-radius: 0 !important;
  transition: all .5s
}
.home_process .card:hover {
  /* background-color: #0078d6; */
  background-color: #014072;
  color: white;
  transition: all .5s;
  transform: translateY(-10px);
}
/* .home_process .card:hover {
  background-color: #45ad3b;
  color: white;
  transition: all .5s;
  transform: translateY(-10px);
} */
.home_process .card-body{
  padding: 40px 7px !important;
}
.home_process .card-body h5{
 font-size: 18px !important;
}
.home_process .card-body p{
 font-size: 14px;
}

.process_circle_main{
  z-index: 1;
  position: absolute;
  background-color: #f8f9fa!important;
  color: white;
  border-radius: 50%;
  padding:12px;
  margin-top: -45px;
  margin-bottom: 20px;
  left: 35%;
}
.process_circle{
  background-color: #45ad3b;
  border-radius: 50%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  font-size: 20px;
  font-weight: 600;
}

.new_row .process_box::after{
 color: #014072;
}




@media only screen and (min-width:1400px) { 
  .new_row .process_box{
  position: relative;
  width: 170px;
  margin:0 20px;

}
.new_row .process_box::after{
  content: '➡';
  position: absolute;
  right: -32px;
  font-size: 30px;
  top: 40%;
}
.new_row .process_circle_main{
  left: 24%;
}

.process_box6::after{
  display: none !important;
  margin-right: 0 !important;
}

}
@media only screen and (max-width:1400px) {
  .new_row .process_box{
    position: relative;
    width: 150px;
    margin:0 18px;
    margin-top: 25px;
  }
  .new_row .process_box::after{
    content: '\27A1';
    position: absolute;
    right: -32px;
    font-size: 28px;
    top: 40%;
  }
  .new_row .process_circle_main{
    left: 20%;
  }
  
  .process_box6::after{
    display: none !important;
    margin-right: 0 !important;
  }
}
@media only screen and (max-width:1200px) {
  .new_row .process_box{
    position: relative;
    width: 180px;
    margin:0 25px;
    margin-top: 25px;
  }
  .new_row .process_box::after{
    content: '\27A1';
    position: absolute;
    right: -35px;
    font-size: 28px;
    top: 40%;
  }
 
  .new_row .process_circle_main{
    left: 25%;
  }
  
  .process_box6::after{
    display: none !important;
    margin-right: 0 !important;
  }
  .process_box4::after{
    display: none !important;
    margin-right: 0 !important;
  }
}
@media only screen and (max-width:992px) {
  .new_row .process_box{
    position: relative;
    width: 180px;
    margin-top: 25px;
  }
 
  .new_row .process_circle_main{
    left: 25%;
  }
  
  .process_box6::after{
    display: none !important;
    margin-right: 0 !important;
  }
  .process_box3::after{
    display: none !important;
    margin-right: 0 !important;
  }
  .process_box4::after{
    display: block !important;
  }

  section#home-banner{
    height: 1200px;
  }
  section#home-banner::after {
    height: 1200px;
  }
}
@media only screen and (max-width:767px) {
  .home_process .card{
    height:245px;}
  .new_row .process_box{
    position: relative;
    width: 180px;
    margin-top: 25px ;
    margin: 10px 20px !important;
  }
  .new_row .process_box::after{
    content: '\27A1';
    position: absolute;
    right: -35px;
    font-size: 28px;
    top: 40%;
  }
 
  .new_row .process_circle_main{
    left: 25%;
  }
  
  .process_box6::after{
    display: none !important;
    margin-right: 0 !important;
  }
  .process_box3::after{
    display: none !important;
    margin-right: 0 !important;
  }
  .process_box3::after{
    display: block !important;
  }
  .process_box4::after{
    display: none !important;
  }
  .process_box2::after{
    display: none !important;
  }

  section#home-banner{
    height: 1300px;
  }
  section#home-banner::after {
    height: 1300px;
  }
}

@media only screen and (max-width:475px) {
  .home_process .card {
    height: 275px;
  }
  .new_row .process_box{
    position: relative;
    width: 150px;
    margin-top: 25px ;
    margin: 10px 15px !important;
  }
  .new_row .process_box::after{
    content: '\27A1';
    position: absolute;
    right: -30px;
    font-size: 28px;
    top: 40%;
  }
 
  .new_row .process_circle_main{
    left: 22%;
  }
  
  .process_box6::after{
    display: none !important;
    margin-right: 0 !important;
  }
  .process_box3::after{
    display: none !important;
    margin-right: 0 !important;
  }
  .process_box3::after{
    display: block !important;
  }
  .process_box4::after{
    display: none !important;
  }
  .process_box2::after{
    display: none !important;
  }
}
@media only screen and (max-width:385px) {
  .home_process .card {
    height: 200px;
  }
  .new_row .process_box{
    position: relative;
    width: 100%;
    margin-top: 25px ;
    margin: 10px 15px !important;
  }
  .new_row .process_box::after{
    content: '\27A1';
    position: absolute;
    right: -30px;
    font-size: 28px;
    top: 40%;
  }
 
  .new_row .process_circle_main{
    left: 35%;
  }
  
  .process_box6::after{
    display: none !important;
    margin-right: 0 !important;
  }
  .process_box3::after{
    display: none !important;
    margin-right: 0 !important;
  }
  .process_box3::after{
    display: block !important;
  }
  .process_box4::after{
    display: none !important;
  }
  .process_box2::after{
    display: none !important;
  }
  .process_box1::after, .process_box3::after, .process_box5::after{
    display: none !important;
  }
  section#home-banner{
    height: 1350px;
  }
  section#home-banner::after {
    height: 1350px;
  }
}


/*---------------------------*/

@media only screen and (max-width:768px) {
  .banner-content h1 {
    font-size: 45px;
    line-height: 55px;
  }
  .banner-content p {
    font-size: 18px;
  }
  .client{
    display: none !important;
  }
}

@media only screen and (max-width:991px) {
  .p1 img {
    display: none;
  }

  .p1 {
    margin-top: 30px;
  }

  .p2 {
    left: 0;
  }

  .p2 img {
    display: none;
  }

  .p3 img {
    display: none;
  }

  .p4 img {
    display: none;
  }

  .p4 {
    right: 0;
  }

  .stats {

    margin-top: 50px;
  }
}

.alliance {
  width: max-content;
  margin: 20px auto;
}

@media only screen and (max-width:567px) {
  .cact {
    padding: 10px;
  }

  .work-process {
    background-color: #f7f7f7;
    border-radius: 50%;
    box-shadow: inset 6px 0 5px 2px #ccc;
    height: 320px;
    width: 320px;
  }

  .banner-content h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .banner-content p {
    font-size: 16px;
  }
  .start-btn {
    font-size: 12px !important;
  }
}