.bg-blue {
  background-color: #014072;
}
.bg-light1 {
  background-color: #f5f5f5;
}
.singleblog {
  padding: 0px 0 30px;
  position: relative;
  top: 66px;
}
.singleblog::after {
  position: absolute;
  right: 150px;
  content: "";
  background: url(../../assets/images/about-top-bg.webp) no-repeat center center /
    cover;
  width: 235px;
  height: 151px;
  top: 25px;
}
.singleblog h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
}
.singleblog p {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}

.singleblog-banner {
  position: relative;
}

.singleblog-description {
  margin-top: 250px;
}

.singleblog-description p {
  font-size: 16px;
}

.singleblog-descriptionwithimage {
  margin-top: 45px;
  margin-bottom: 45px;
}

.singleblog-descriptionwithimage p {
  font-size: 16px;
}

.singleblog-desc3 p {
  font-size: 16px;
}

.singleblog-desc3 span {
  font-size: 16px;
  font-weight: 500;
}

.singleblog h2{
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .singleblog-description {
    padding-top: 90%;
  }

  .singleblog-sociallink {
    flex-direction: column;
  }
}

@media only screen and (max-width: 577px) {
  .singleblog-description {
    padding-top: 80%;
  }
}

.blogpost_para {
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  clear: both;
}

.singleblogback {
  background-color: #014072;
  padding-top: 80px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Single_blog_content {
  padding-top: 0px;
  margin-bottom: 50px;
}
.Single_blog_content strong {
  display: inline-block;
  margin-top: 10px;
}
#stickyFormContainer {
  position: relative; /* Ensure this container serves as a reference for the sticky form */
  margin-top: 50px; /* Adjust this value according to your design */
}

#stickyForm {
  position: sticky;
  top: 90px; /* Adjust this value according to your design */
  z-index: 1000; /* Adjust the z-index to ensure it overlays other content properly */
}

.singleblog_form {
  background-color: #f1f2f3;
  border-radius: 10px;
  margin-left: 30px;
  width: 100%;
  padding: 35px;
  margin-top: 80px;
  margin-left: 0px;
}

.singleblogback h2 {
  color: #f5f5f5;
}

.newcontent img {
  width: 100%;
}

/*-------Loader-------*/

.loader_div{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 50px;
  height: 50px;
  border: 5px solid rgb(215, 215, 215);
  border-bottom-color: green;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  }
/*-------Loader-------*/




@media only screen and (max-width: 991px) {
  #sticky {
    padding: 50px 75px;
  }
}

@media only screen and (max-width: 767px) {
  .singleblog {
    padding: 0;
  }
  .stickyformcontainer .singleblog_form {
    width: 100% !important;
    max-width: 100% !important;
  }
}
